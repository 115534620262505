import { useState, useEffect } from 'react'

// https://github.com/JedWatson/react-select/issues/3590
function useMounted() {
  const [hasMounted, setHasMounted] = useState(false)
  useEffect(() => {
    setHasMounted(true)
  }, [])
  return { hasMounted }
}

export function ClientOnly(props: { children: React.ReactNode }) {
  const { hasMounted } = useMounted()

  if (!hasMounted) {
    return null
  }

  return props.children
}
