/* eslint-disable */
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never }
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never
    }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string }
  String: { input: string; output: string }
  Boolean: { input: boolean; output: boolean }
  Int: { input: number; output: number }
  Float: { input: number; output: number }
  Cursor: { input: any; output: any }
  DateTime: { input: any; output: any }
  EmailAddress: { input: any; output: any }
  URL: { input: any; output: any }
  Upload: { input: any; output: any }
}

export enum AddToCartError {
  NotAvailable = 'NOT_AVAILABLE',
  NotFound = 'NOT_FOUND',
}

export type AddToCartResult = {
  __typename?: 'AddToCartResult'
  error?: Maybe<AddToCartError>
  ok?: Maybe<Scalars['Boolean']['output']>
}

export enum AgeRestriction {
  Over_16WithAdult = 'OVER_16_WITH_ADULT',
  Over_18 = 'OVER_18',
  Under_16WithAdult = 'UNDER_16_WITH_ADULT',
}

/** Can create events and perform in events created by others. */
export type Artist = Node & {
  __typename?: 'Artist'
  coverPicture?: Maybe<Scalars['String']['output']>
  description: Scalars['String']['output']
  eventInvitations: Array<EventInvitation>
  events: EventConnection
  genres: Array<Genre>
  id: Scalars['ID']['output']
  links: Array<Link>
  name: Scalars['String']['output']
  picture?: Maybe<Scalars['String']['output']>
  slug: Scalars['String']['output']
}

/** Can create events and perform in events created by others. */
export type ArtistEventsArgs = {
  pagination: ForwardPagination
}

export type ArtistConnection = Connection & {
  __typename?: 'ArtistConnection'
  edges: Array<ArtistEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']['output']
}

export type ArtistEdge = Edge & {
  __typename?: 'ArtistEdge'
  cursor: Scalars['Cursor']['output']
  node: Artist
}

export enum ArtistProfileCreationError {
  NameTaken = 'NAME_TAKEN',
}

export type ArtistProfileCreationResult = {
  __typename?: 'ArtistProfileCreationResult'
  artist?: Maybe<Artist>
  error?: Maybe<ArtistProfileCreationError>
  ok: Scalars['Boolean']['output']
}

export type ArtistProfileUpdateOptions = {
  description?: InputMaybe<Scalars['String']['input']>
  genres?: InputMaybe<Array<Scalars['String']['input']>>
  id: Scalars['ID']['input']
  links?: InputMaybe<Array<LinkInput>>
  name?: InputMaybe<Scalars['String']['input']>
  picture?: InputMaybe<Scalars['String']['input']>
  slug?: InputMaybe<Scalars['String']['input']>
}

export type ArtistSignupInput = {
  description: Scalars['String']['input']
  eventInvitationToken?: InputMaybe<Scalars['String']['input']>
  genres: Array<Scalars['String']['input']>
  name: Scalars['String']['input']
  spotify?: InputMaybe<Scalars['String']['input']>
}

export type Cart = {
  __typename?: 'Cart'
  items: Array<CartItem>
  total: Price
}

export type CartItem = {
  __typename?: 'CartItem'
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  price: Price
  quantity: Scalars['Int']['output']
  type: ProductType
}

export type CheckoutError = ProductCheckoutError

export type CheckoutLineItem = {
  id: Scalars['ID']['input']
  quantity: Scalars['Int']['input']
}

export type CheckoutResult = {
  __typename?: 'CheckoutResult'
  errors?: Maybe<Array<Maybe<CheckoutError>>>
  ok?: Maybe<CheckoutSession>
}

export type CheckoutSession = {
  __typename?: 'CheckoutSession'
  status?: Maybe<Scalars['String']['output']>
  url?: Maybe<Scalars['URL']['output']>
}

export type City = {
  __typename?: 'City'
  /** Unimplemented */
  country?: Maybe<Country>
  id: Scalars['Int']['output']
  name: Scalars['String']['output']
}

export type Connection = {
  edges: Array<Edge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']['output']
}

export type Constellation = {
  __typename?: 'Constellation'
  points: Array<ConstellationPoint>
}

export type ConstellationPoint = {
  __typename?: 'ConstellationPoint'
  coordinates: Array<Scalars['Float']['output']>
  image?: Maybe<Scalars['String']['output']>
  name: Scalars['String']['output']
  resourceId: Scalars['ID']['output']
  resourceType: ProfileType
  slug: Scalars['String']['output']
}

export type Country = {
  __typename?: 'Country'
  id: Scalars['Int']['output']
  name: Scalars['String']['output']
}

export type CreateEventInput = {
  artistIds: Array<Scalars['ID']['input']>
  description: Scalars['String']['input']
  emailInvitations?: InputMaybe<Array<EmailInvitationInput>>
  genreIds: Array<Scalars['Int']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  picture?: InputMaybe<Scalars['String']['input']>
  promoterId?: InputMaybe<Scalars['ID']['input']>
  revenueSplits?: InputMaybe<Array<RevenueSplitInput>>
  startTime: Scalars['DateTime']['input']
  tickets?: InputMaybe<CreateTicketsInput>
  venueId?: InputMaybe<Scalars['ID']['input']>
}

export type CreateTicketsInput = {
  price: Scalars['Float']['input']
  quantity: Scalars['Int']['input']
  useVenueAudienceCapacityForQuantity?: InputMaybe<Scalars['Boolean']['input']>
}

export enum Currency {
  Gbp = 'GBP',
}

export type Customer = {
  __typename?: 'Customer'
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

export type Edge = {
  cursor: Scalars['Cursor']['output']
  node: Node
}

export type EmailInvitationInput = {
  email: Scalars['EmailAddress']['input']
  profileType: ProfileType
}

export type Error = {
  __typename?: 'Error'
  error?: Maybe<Scalars['String']['output']>
}

export type Event = Node & {
  __typename?: 'Event'
  allParticipantsAccepted?: Maybe<Scalars['Boolean']['output']>
  artistIdsAccepted: Array<Scalars['ID']['output']>
  artists: Array<Artist>
  canBuyProducts?: Maybe<Scalars['Boolean']['output']>
  city?: Maybe<City>
  contextMenu: EventContextMenuResult
  description?: Maybe<Scalars['String']['output']>
  genres: Array<Maybe<Genre>>
  id: Scalars['ID']['output']
  isSaved?: Maybe<Scalars['Boolean']['output']>
  name?: Maybe<Scalars['String']['output']>
  orders?: Maybe<OrderConnection>
  picture?: Maybe<Scalars['String']['output']>
  products: Array<Product>
  promoter?: Maybe<Promoter>
  promoterAccepted?: Maybe<Scalars['Boolean']['output']>
  published?: Maybe<Scalars['Boolean']['output']>
  revenueBreakdown?: Maybe<RevenueBreakdown>
  revenueSplits: Array<RevenueSplit>
  slug: Scalars['String']['output']
  startTime: Scalars['String']['output']
  ticketStats?: Maybe<TicketStats>
  venue?: Maybe<Venue>
  venueAccepted?: Maybe<Scalars['Boolean']['output']>
}

export type EventOrdersArgs = {
  filter?: InputMaybe<OrderFilter>
  pagination: ForwardPagination
}

export type EventConnection = Connection & {
  __typename?: 'EventConnection'
  edges: Array<EventEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']['output']
}

export enum EventContextMenuErrorCode {
  NotPermitted = 'NOT_PERMITTED',
}

export enum EventContextMenuItem {
  Admission = 'ADMISSION',
  Delete = 'DELETE',
  Edit = 'EDIT',
  Report = 'REPORT',
  UntagSelectedProfile = 'UNTAG_SELECTED_PROFILE',
  ViewOrders = 'VIEW_ORDERS',
  ViewSummary = 'VIEW_SUMMARY',
}

export type EventContextMenuResult = {
  __typename?: 'EventContextMenuResult'
  error?: Maybe<EventContextMenuErrorCode>
  ok?: Maybe<Array<EventContextMenuItem>>
}

export type EventCreationError = {
  __typename?: 'EventCreationError'
  code: EventCreationErrorCode
  description: Scalars['String']['output']
}

export enum EventCreationErrorCode {
  ArtistNotFound = 'ARTIST_NOT_FOUND',
  InvalidStartTime = 'INVALID_START_TIME',
  InvalidTicketPrice = 'INVALID_TICKET_PRICE',
  MissingRevenueSplits = 'MISSING_REVENUE_SPLITS',
  NotPermitted = 'NOT_PERMITTED',
  PromoterNotFound = 'PROMOTER_NOT_FOUND',
  RevenueSplitNotAddingUp = 'REVENUE_SPLIT_NOT_ADDING_UP',
  RevenueSplitProfileNotParticipant = 'REVENUE_SPLIT_PROFILE_NOT_PARTICIPANT',
  VenueNotFound = 'VENUE_NOT_FOUND',
}

export type EventCreationResult = {
  __typename?: 'EventCreationResult'
  error?: Maybe<EventCreationError>
  ok?: Maybe<Event>
}

export type EventDeletionError = {
  __typename?: 'EventDeletionError'
  code: EventDeletionErrorCode
  description: Scalars['String']['output']
}

export enum EventDeletionErrorCode {
  NotPermitted = 'NOT_PERMITTED',
}

export type EventDeletionResult = {
  __typename?: 'EventDeletionResult'
  error?: Maybe<EventDeletionError>
  ok?: Maybe<Scalars['Boolean']['output']>
}

export type EventEdge = Edge & {
  __typename?: 'EventEdge'
  cursor: Scalars['Cursor']['output']
  node: Event
}

export type EventFilter = {
  allParticipantsAccepted?: InputMaybe<Scalars['Boolean']['input']>
  completed?: InputMaybe<Scalars['Boolean']['input']>
}

export type EventInvitation = {
  __typename?: 'EventInvitation'
  eventId: Scalars['ID']['output']
  eventSlug: Scalars['ID']['output']
  senderProfile: Profile
  status: EventInvitationStatus
  token: Scalars['String']['output']
}

export enum EventInvitationErrorCode {
  NotPermitted = 'NOT_PERMITTED',
}

export type EventInvitationResult = {
  __typename?: 'EventInvitationResult'
  error?: Maybe<EventInvitationErrorCode>
  ok?: Maybe<Scalars['Boolean']['output']>
}

export enum EventInvitationStatus {
  Ignored = 'IGNORED',
  Initial = 'INITIAL',
}

export type EventParticipant = Artist | Promoter | Venue

export type EventUntaggingError = {
  __typename?: 'EventUntaggingError'
  code: EventUntaggingErrorCode
  description: Scalars['String']['output']
}

export enum EventUntaggingErrorCode {
  NotPermitted = 'NOT_PERMITTED',
}

export type EventUntaggingResult = {
  __typename?: 'EventUntaggingResult'
  error?: Maybe<EventUntaggingError>
  ok?: Maybe<Scalars['Boolean']['output']>
}

export enum EventUpdateArtistsErrorCode {
  ArtistNotFound = 'ARTIST_NOT_FOUND',
  NotPermitted = 'NOT_PERMITTED',
  NotSupportedEventHasRevenueSplits = 'NOT_SUPPORTED_EVENT_HAS_REVENUE_SPLITS',
}

export type EventUpdateArtistsResult = {
  __typename?: 'EventUpdateArtistsResult'
  error?: Maybe<EventUpdateArtistsErrorCode>
  ok?: Maybe<Event>
}

export enum EventUpdateDescriptionErrorCode {
  NotPermitted = 'NOT_PERMITTED',
}

export type EventUpdateDescriptionResult = {
  __typename?: 'EventUpdateDescriptionResult'
  error?: Maybe<EventUpdateDescriptionErrorCode>
  ok?: Maybe<Event>
}

export enum EventUpdateGenresErrorCode {
  NotFound = 'NOT_FOUND',
  NotPermitted = 'NOT_PERMITTED',
}

export type EventUpdateGenresResult = {
  __typename?: 'EventUpdateGenresResult'
  error?: Maybe<EventUpdateGenresErrorCode>
  ok?: Maybe<Event>
}

export enum EventUpdateNameErrorCode {
  NotPermitted = 'NOT_PERMITTED',
}

export type EventUpdateNameResult = {
  __typename?: 'EventUpdateNameResult'
  error?: Maybe<EventUpdateNameErrorCode>
  ok?: Maybe<Event>
}

export enum EventUpdatePosterErrorCode {
  NotPermitted = 'NOT_PERMITTED',
}

export type EventUpdatePosterResult = {
  __typename?: 'EventUpdatePosterResult'
  error?: Maybe<EventUpdatePosterErrorCode>
  ok?: Maybe<Event>
}

export enum EventUpdatePublishedErrorCode {
  NotPermitted = 'NOT_PERMITTED',
}

export type EventUpdatePublishedResult = {
  __typename?: 'EventUpdatePublishedResult'
  error?: Maybe<EventUpdatePublishedErrorCode>
  ok?: Maybe<Event>
}

export enum EventUpdateTimeErrorCode {
  CannotBeInThePast = 'CANNOT_BE_IN_THE_PAST',
  NotPermitted = 'NOT_PERMITTED',
}

export type EventUpdateTimeResult = {
  __typename?: 'EventUpdateTimeResult'
  error?: Maybe<EventUpdateTimeErrorCode>
  ok?: Maybe<Event>
}

export enum EventUpdateVenueErrorCode {
  NotFound = 'NOT_FOUND',
  NotPermitted = 'NOT_PERMITTED',
  NotSupportedEventHasRevenueSplits = 'NOT_SUPPORTED_EVENT_HAS_REVENUE_SPLITS',
}

export type EventUpdateVenueResult = {
  __typename?: 'EventUpdateVenueResult'
  error?: Maybe<EventUpdateVenueErrorCode>
  ok?: Maybe<Event>
}

export type ForwardPagination = {
  after?: InputMaybe<Scalars['Cursor']['input']>
  first: Scalars['Int']['input']
}

/** Used as search tags for events, venues and artists. */
export type Genre = {
  __typename?: 'Genre'
  artists?: Maybe<ArtistConnection>
  eventCount?: Maybe<Scalars['Int']['output']>
  events?: Maybe<EventConnection>
  id: Scalars['Int']['output']
  name: Scalars['String']['output']
}

/** Used as search tags for events, venues and artists. */
export type GenreArtistsArgs = {
  cityId: Scalars['Int']['input']
  pagination: ForwardPagination
}

/** Used as search tags for events, venues and artists. */
export type GenreEventsArgs = {
  cityId: Scalars['Int']['input']
  pagination: ForwardPagination
}

export type GetPermissionsOptions = {
  profileId: Scalars['ID']['input']
  profileType: ProfileType
}

export type JoinWaitListError = {
  __typename?: 'JoinWaitListError'
  code: JoinWaitListErrorCode
  description: Scalars['String']['output']
}

export enum JoinWaitListErrorCode {
  InvalidEmail = 'INVALID_EMAIL',
}

export type JoinWaitListResult = {
  __typename?: 'JoinWaitListResult'
  error?: Maybe<JoinWaitListError>
  ok?: Maybe<Scalars['Boolean']['output']>
}

export type Link = {
  __typename?: 'Link'
  name: Scalars['String']['output']
  url: Scalars['String']['output']
}

export type LinkInput = {
  name: Scalars['String']['input']
  url: Scalars['String']['input']
}

export type LoginError = {
  __typename?: 'LoginError'
  code: LoginErrorCode
  description: Scalars['String']['output']
}

export enum LoginErrorCode {
  InvalidCredentials = 'INVALID_CREDENTIALS',
  MultiFactorAuthenticationRequired = 'MULTI_FACTOR_AUTHENTICATION_REQUIRED',
}

export type LoginInput = {
  email: Scalars['EmailAddress']['input']
  oneTimePassword?: InputMaybe<Scalars['String']['input']>
  password: Scalars['String']['input']
}

export type LoginResult = {
  __typename?: 'LoginResult'
  error?: Maybe<LoginError>
  ok: Scalars['Boolean']['output']
  user?: Maybe<User>
}

export type Mutation = {
  __typename?: 'Mutation'
  acceptEventInvitation?: Maybe<EventInvitationResult>
  addToCart: AddToCartResult
  changePassword: PasswordChangeResult
  checkOut?: Maybe<CheckoutResult>
  clearCart: Cart
  createArtist: ArtistProfileCreationResult
  createEvent?: Maybe<EventCreationResult>
  createVenue: VenueProfileCreationResult
  deleteAccount?: Maybe<Scalars['Boolean']['output']>
  deleteEvent: EventDeletionResult
  getOrCreateStripeAccountLink: StripeAccountLinkResult
  ignoreEventInvitation?: Maybe<EventInvitationResult>
  inviteProfileMember: ProfileMemberInvitationResult
  joinWaitList: JoinWaitListResult
  logOut: Scalars['Boolean']['output']
  login?: Maybe<LoginResult>
  newArtistProfilePicture: ProfilePictureUpdateResult
  newPromoterProfilePicture: ProfilePictureUpdateResult
  newUserProfilePicture: Scalars['String']['output']
  newVenueProfilePicture: ProfilePictureUpdateResult
  removeFromCart?: Maybe<Scalars['Boolean']['output']>
  resetPassword?: Maybe<Scalars['Boolean']['output']>
  saveEvent?: Maybe<Event>
  scanTickets: Array<ScanTicketResult>
  scanTicketsInOrders: ScanTicketsInOrdersResult
  sendResetPasswordEmail?: Maybe<Scalars['Boolean']['output']>
  setCartItemQuantity?: Maybe<Scalars['Boolean']['output']>
  setSelectedProfile?: Maybe<Profile>
  signUp?: Maybe<Scalars['Boolean']['output']>
  signUpArtist?: Maybe<Scalars['Boolean']['output']>
  signUpPromoter?: Maybe<Scalars['Boolean']['output']>
  signUpVenue?: Maybe<Scalars['Boolean']['output']>
  slugExists?: Maybe<Scalars['Boolean']['output']>
  unsaveEvent?: Maybe<Event>
  /** Removes an artist profile (managed by a user) from an event. */
  untagFromEvent: EventUntaggingResult
  updateArtistProfile: ProfileUpdateResult
  updateEventArtists: EventUpdateArtistsResult
  updateEventDescription: EventUpdateDescriptionResult
  updateEventGenres: EventUpdateGenresResult
  updateEventName: EventUpdateNameResult
  updateEventPoster: EventUpdatePosterResult
  updateEventPublished: EventUpdatePublishedResult
  updateEventTime: EventUpdateTimeResult
  updateEventVenue: EventUpdateVenueResult
  updateProfilePermissions: ProfileUpdateResult
  updatePromoterProfile: ProfileUpdateResult
  updateUserProfile: ProfileUpdateResult
  updateVenueProfile: ProfileUpdateResult
  verifyEmail?: Maybe<Scalars['Boolean']['output']>
}

export type MutationAcceptEventInvitationArgs = {
  token: Scalars['String']['input']
}

export type MutationAddToCartArgs = {
  itemId: Scalars['ID']['input']
  quantity: Scalars['Int']['input']
}

export type MutationChangePasswordArgs = {
  newPassword: Scalars['String']['input']
  oldPassword: Scalars['String']['input']
}

export type MutationCheckOutArgs = {
  attribution?: InputMaybe<Scalars['String']['input']>
  buyNowItems?: InputMaybe<Array<CheckoutLineItem>>
}

export type MutationCreateArtistArgs = {
  input: ArtistSignupInput
}

export type MutationCreateEventArgs = {
  event: CreateEventInput
}

export type MutationCreateVenueArgs = {
  input: VenueSignupInput
}

export type MutationDeleteEventArgs = {
  id: Scalars['ID']['input']
}

export type MutationIgnoreEventInvitationArgs = {
  token: Scalars['String']['input']
}

export type MutationInviteProfileMemberArgs = {
  options: ProfileMemberInvitationOptions
}

export type MutationJoinWaitListArgs = {
  email: Scalars['EmailAddress']['input']
}

export type MutationLoginArgs = {
  input: LoginInput
}

export type MutationNewArtistProfilePictureArgs = {
  picture: Scalars['Upload']['input']
  profileId: Scalars['ID']['input']
}

export type MutationNewPromoterProfilePictureArgs = {
  picture: Scalars['Upload']['input']
  profileId: Scalars['ID']['input']
}

export type MutationNewUserProfilePictureArgs = {
  picture: Scalars['Upload']['input']
}

export type MutationNewVenueProfilePictureArgs = {
  picture: Scalars['Upload']['input']
  profileId: Scalars['ID']['input']
}

export type MutationRemoveFromCartArgs = {
  itemId: Scalars['ID']['input']
}

export type MutationResetPasswordArgs = {
  password: Scalars['String']['input']
  token: Scalars['String']['input']
}

export type MutationSaveEventArgs = {
  id: Scalars['ID']['input']
}

export type MutationScanTicketsArgs = {
  eventId: Scalars['ID']['input']
  urls: Array<Scalars['URL']['input']>
}

export type MutationScanTicketsInOrdersArgs = {
  orderIds: Array<Scalars['ID']['input']>
}

export type MutationSendResetPasswordEmailArgs = {
  email: Scalars['EmailAddress']['input']
}

export type MutationSetCartItemQuantityArgs = {
  itemId: Scalars['ID']['input']
  quantity: Scalars['Int']['input']
}

export type MutationSetSelectedProfileArgs = {
  slug: Scalars['String']['input']
}

export type MutationSignUpArgs = {
  input: UserSignupInput
}

export type MutationSignUpArtistArgs = {
  artist: ArtistSignupInput
  user: UserSignupInput
}

export type MutationSignUpPromoterArgs = {
  promoter: PromoterSignupInput
  user: UserSignupInput
}

export type MutationSignUpVenueArgs = {
  user: UserSignupInput
  venue: VenueSignupInput
}

export type MutationSlugExistsArgs = {
  slug: Scalars['String']['input']
}

export type MutationUnsaveEventArgs = {
  id: Scalars['ID']['input']
}

export type MutationUntagFromEventArgs = {
  eventId: Scalars['ID']['input']
  profileId: Scalars['ID']['input']
}

export type MutationUpdateArtistProfileArgs = {
  options: ArtistProfileUpdateOptions
}

export type MutationUpdateEventArtistsArgs = {
  options: UpdateEventArtistsInput
}

export type MutationUpdateEventDescriptionArgs = {
  options: UpdateEventDescriptionInput
}

export type MutationUpdateEventGenresArgs = {
  options: UpdateEventGenresInput
}

export type MutationUpdateEventNameArgs = {
  options: UpdateEventNameInput
}

export type MutationUpdateEventPosterArgs = {
  options: UpdateEventPosterInput
}

export type MutationUpdateEventPublishedArgs = {
  options: UpdateEventPublishedInput
}

export type MutationUpdateEventTimeArgs = {
  options: UpdateEventTimeInput
}

export type MutationUpdateEventVenueArgs = {
  options: UpdateEventVenueInput
}

export type MutationUpdateProfilePermissionsArgs = {
  options: UpdatePermissionsOptions
}

export type MutationUpdatePromoterProfileArgs = {
  options: PromoterProfileUpdateOptions
}

export type MutationUpdateUserProfileArgs = {
  options: UserProfileUpdateOptions
}

export type MutationUpdateVenueProfileArgs = {
  options: VenueProfileUpdateOptions
}

export type MutationVerifyEmailArgs = {
  token: Scalars['String']['input']
}

export type Node = {
  id: Scalars['ID']['output']
}

export type OnboardingStep = {
  __typename?: 'OnboardingStep'
  isCompleted: Scalars['Boolean']['output']
  name: OnboardingStepName
}

export enum OnboardingStepName {
  ConnectStripeAccount = 'CONNECT_STRIPE_ACCOUNT',
  MultiFactorAuthentication = 'MULTI_FACTOR_AUTHENTICATION',
  PostEvent = 'POST_EVENT',
  ProfilePicture = 'PROFILE_PICTURE',
}

export type Order = Node & {
  __typename?: 'Order'
  createdAt: Scalars['DateTime']['output']
  customer: Customer
  id: Scalars['ID']['output']
  net: Price
  orderProducts: Array<OrderProduct>
  total: Price
  yuptyFee: Price
}

export type OrderConnection = Connection & {
  __typename?: 'OrderConnection'
  edges: Array<OrderEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']['output']
}

export type OrderEdge = Edge & {
  __typename?: 'OrderEdge'
  cursor: Scalars['Cursor']['output']
  node: Order
}

export type OrderFilter = {
  userName?: InputMaybe<Scalars['String']['input']>
}

export type OrderProduct = {
  __typename?: 'OrderProduct'
  id: Scalars['ID']['output']
  product: Product
  quantity: Scalars['Int']['output']
  scanned: Scalars['Boolean']['output']
}

export type PageInfo = {
  __typename?: 'PageInfo'
  endCursor?: Maybe<Scalars['Cursor']['output']>
  hasNextPage: Scalars['Boolean']['output']
}

export type PasswordChangeError = {
  __typename?: 'PasswordChangeError'
  code: PasswordChangeErrorCode
  description: Scalars['String']['output']
}

export enum PasswordChangeErrorCode {
  NewPasswordNotStrongEnough = 'NEW_PASSWORD_NOT_STRONG_ENOUGH',
  WrongOldPassword = 'WRONG_OLD_PASSWORD',
}

export type PasswordChangeResult = {
  __typename?: 'PasswordChangeResult'
  error?: Maybe<PasswordChangeError>
  ok: Scalars['Boolean']['output']
}

export type Permission = {
  __typename?: 'Permission'
  description: Scalars['String']['output']
  id: Scalars['Int']['output']
  name: Scalars['String']['output']
}

export type Price = {
  __typename?: 'Price'
  amount: Scalars['Float']['output']
  currency: Currency
}

export type PricingEstimationInput = {
  revenueSplits: Array<RevenueSplitInput>
  ticketPrice: Scalars['Float']['input']
  ticketsPerOrder: Scalars['Int']['input']
  ticketsSold: Scalars['Int']['input']
}

export type Product = {
  __typename?: 'Product'
  event?: Maybe<Event>
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  price: Price
  quantity: Scalars['Int']['output']
  type: ProductType
}

export type ProductCheckoutError = {
  __typename?: 'ProductCheckoutError'
  code: ProductCheckoutErrorCode
  forItemId: Scalars['ID']['output']
}

export enum ProductCheckoutErrorCode {
  InsufficientQuantity = 'INSUFFICIENT_QUANTITY',
  ProductNotFound = 'PRODUCT_NOT_FOUND',
}

export enum ProductType {
  Ticket = 'TICKET',
}

export type Profile = Artist | Promoter | User | Venue

export type ProfileMemberInvitationError = {
  __typename?: 'ProfileMemberInvitationError'
  code: ProfileMemberInvitationErrorCode
  description: Scalars['String']['output']
}

export enum ProfileMemberInvitationErrorCode {
  NotPermitted = 'NOT_PERMITTED',
}

export type ProfileMemberInvitationOptions = {
  email: Scalars['EmailAddress']['input']
  permissionIds: Array<Scalars['Int']['input']>
  profileId: Scalars['ID']['input']
  profileType: ProfileType
}

export type ProfileMemberInvitationResult = {
  __typename?: 'ProfileMemberInvitationResult'
  error?: Maybe<ProfileMemberInvitationError>
  ok: Scalars['Boolean']['output']
}

export type ProfilePictureUpdateResult = {
  __typename?: 'ProfilePictureUpdateResult'
  error?: Maybe<ProfileUpdateError>
  ok?: Maybe<Scalars['String']['output']>
}

export enum ProfileType {
  Artist = 'ARTIST',
  Promoter = 'PROMOTER',
  User = 'USER',
  Venue = 'VENUE',
}

export type ProfileUpdateError = {
  __typename?: 'ProfileUpdateError'
  code: ProfileUpdateErrorCode
  description: Scalars['String']['output']
}

export enum ProfileUpdateErrorCode {
  NotPermitted = 'NOT_PERMITTED',
}

export type ProfileUpdateResult = {
  __typename?: 'ProfileUpdateResult'
  error?: Maybe<ProfileUpdateError>
  ok: Scalars['Boolean']['output']
}

export type Promoter = Node & {
  __typename?: 'Promoter'
  description: Scalars['String']['output']
  eventInvitations: Array<EventInvitation>
  events: EventConnection
  id: Scalars['ID']['output']
  links: Array<Link>
  name: Scalars['String']['output']
  picture?: Maybe<Scalars['String']['output']>
  slug: Scalars['String']['output']
}

export type PromoterEventsArgs = {
  pagination: ForwardPagination
}

export type PromoterConnection = Connection & {
  __typename?: 'PromoterConnection'
  edges: Array<PromoterEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']['output']
}

export type PromoterEdge = Edge & {
  __typename?: 'PromoterEdge'
  cursor: Scalars['Cursor']['output']
  node: Promoter
}

export type PromoterProfileUpdateOptions = {
  description?: InputMaybe<Scalars['String']['input']>
  id: Scalars['ID']['input']
  links?: InputMaybe<Array<LinkInput>>
  name?: InputMaybe<Scalars['String']['input']>
  picture?: InputMaybe<Scalars['String']['input']>
  slug?: InputMaybe<Scalars['String']['input']>
}

export type PromoterSignupInput = {
  description: Scalars['String']['input']
  eventInvitationToken?: InputMaybe<Scalars['String']['input']>
  name: Scalars['String']['input']
}

export type Query = {
  __typename?: 'Query'
  allGenres: Array<Genre>
  /** What profile permissions can the current user assign to other users */
  assignablePermissions: Array<Permission>
  currentUser?: Maybe<User>
  emailExistsVerified?: Maybe<Scalars['Boolean']['output']>
  genresByName: Array<Genre>
  genresWithEventCount: Array<Genre>
  getArtistById?: Maybe<Artist>
  getArtistBySlug?: Maybe<Artist>
  /** Get a list of artists, searching by name (or part of it). */
  getArtistsByName: ArtistConnection
  /** Get a list of art"ists that have events going on. */
  getArtistsForArtistFeed: ArtistConnection
  getCart: Cart
  getCities: Array<City>
  getConstellation: Constellation
  getEventById?: Maybe<Event>
  getEventBySlug?: Maybe<Event>
  getEventContextMenu: EventContextMenuResult
  getEventsByArtistId: EventConnection
  getEventsByGenreIds: EventConnection
  getEventsByIds: EventConnection
  getEventsByPromoterId: EventConnection
  getEventsByVenueId: EventConnection
  getLatestEvents: EventConnection
  getMyOrders: OrderConnection
  getPromoterById?: Maybe<Promoter>
  getPromoterBySlug?: Maybe<Promoter>
  getPromotersByName: PromoterConnection
  getRandomEvents: EventConnection
  getRevenueBreakdownEstimate?: Maybe<RevenueBreakdown>
  getSavedEvents: EventConnection
  getStripeLoginLink?: Maybe<Scalars['String']['output']>
  getUsersWithPermissions: Array<User>
  getVenueById?: Maybe<Venue>
  getVenueBySlug?: Maybe<Venue>
  getVenuesByName: VenueConnection
  getVenuesForVenueFeed: VenueConnection
  onboardingSteps: Array<OnboardingStep>
  search?: Maybe<SearchResults>
}

export type QueryAssignablePermissionsArgs = {
  profileId: Scalars['ID']['input']
  type: ProfileType
}

export type QueryEmailExistsVerifiedArgs = {
  email: Scalars['EmailAddress']['input']
}

export type QueryGenresByNameArgs = {
  name: Scalars['String']['input']
}

export type QueryGenresWithEventCountArgs = {
  cityId: Scalars['ID']['input']
}

export type QueryGetArtistByIdArgs = {
  id: Scalars['ID']['input']
}

export type QueryGetArtistBySlugArgs = {
  slug: Scalars['String']['input']
}

export type QueryGetArtistsByNameArgs = {
  name: Scalars['String']['input']
  pagination: ForwardPagination
}

export type QueryGetArtistsForArtistFeedArgs = {
  cityId: Scalars['Int']['input']
  pagination: ForwardPagination
}

export type QueryGetConstellationArgs = {
  cityId: Scalars['Int']['input']
}

export type QueryGetEventByIdArgs = {
  id: Scalars['ID']['input']
}

export type QueryGetEventBySlugArgs = {
  slug: Scalars['String']['input']
}

export type QueryGetEventContextMenuArgs = {
  id?: InputMaybe<Scalars['ID']['input']>
  slug?: InputMaybe<Scalars['String']['input']>
}

export type QueryGetEventsByArtistIdArgs = {
  artistId: Scalars['ID']['input']
  filter?: InputMaybe<EventFilter>
  pagination: ForwardPagination
}

export type QueryGetEventsByGenreIdsArgs = {
  cityId: Scalars['Int']['input']
  genreIds: Array<Scalars['Int']['input']>
  pagination: ForwardPagination
}

export type QueryGetEventsByIdsArgs = {
  eventIds: Array<Scalars['ID']['input']>
  pagination: ForwardPagination
}

export type QueryGetEventsByPromoterIdArgs = {
  filter?: InputMaybe<EventFilter>
  pagination: ForwardPagination
  promoterId: Scalars['ID']['input']
}

export type QueryGetEventsByVenueIdArgs = {
  filter?: InputMaybe<EventFilter>
  pagination: ForwardPagination
  venueId: Scalars['ID']['input']
}

export type QueryGetLatestEventsArgs = {
  cityId: Scalars['Int']['input']
  pagination: ForwardPagination
}

export type QueryGetMyOrdersArgs = {
  pagination: ForwardPagination
  past?: InputMaybe<Scalars['Boolean']['input']>
}

export type QueryGetPromoterByIdArgs = {
  id: Scalars['ID']['input']
}

export type QueryGetPromoterBySlugArgs = {
  slug: Scalars['String']['input']
}

export type QueryGetPromotersByNameArgs = {
  name: Scalars['String']['input']
  pagination: ForwardPagination
}

export type QueryGetRandomEventsArgs = {
  cityId: Scalars['Int']['input']
  pagination: ForwardPagination
}

export type QueryGetRevenueBreakdownEstimateArgs = {
  input: PricingEstimationInput
}

export type QueryGetSavedEventsArgs = {
  pagination: ForwardPagination
}

export type QueryGetUsersWithPermissionsArgs = {
  options: GetPermissionsOptions
}

export type QueryGetVenueByIdArgs = {
  id: Scalars['ID']['input']
}

export type QueryGetVenueBySlugArgs = {
  slug: Scalars['String']['input']
}

export type QueryGetVenuesByNameArgs = {
  name: Scalars['String']['input']
  pagination: ForwardPagination
}

export type QueryGetVenuesForVenueFeedArgs = {
  cityId: Scalars['Int']['input']
  pagination: ForwardPagination
}

export type QuerySearchArgs = {
  text: Scalars['String']['input']
}

export type RevenueBreakdown = {
  __typename?: 'RevenueBreakdown'
  currency: Currency
  gross: Scalars['Float']['output']
  net: Scalars['Float']['output']
  netRevenueByParticipant: Array<RevenueByParticipant>
  yuptyFee: Scalars['Float']['output']
}

export type RevenueByParticipant = {
  __typename?: 'RevenueByParticipant'
  amount: Scalars['Float']['output']
  currency: Currency
  participant: EventParticipant
  revenueSplitPercent: Scalars['Float']['output']
}

export type RevenueSplit = {
  __typename?: 'RevenueSplit'
  percent: Scalars['Float']['output']
  profileId: Scalars['ID']['output']
  profileType?: Maybe<ProfileType>
}

export type RevenueSplitInput = {
  profileId: Scalars['ID']['input']
  profileType: ProfileType
  value: Scalars['Float']['input']
}

export enum ScanTicketErrorCode {
  AlreadyScanned = 'ALREADY_SCANNED',
  NotPermitted = 'NOT_PERMITTED',
  TicketInvalid = 'TICKET_INVALID',
  TicketIsForAnotherEvent = 'TICKET_IS_FOR_ANOTHER_EVENT',
}

export type ScanTicketResult = {
  __typename?: 'ScanTicketResult'
  error?: Maybe<ScanTicketErrorCode>
  id?: Maybe<Scalars['URL']['output']>
  ok?: Maybe<Scalars['Boolean']['output']>
  time?: Maybe<Scalars['DateTime']['output']>
}

export enum ScanTicketsInOrdersErrorCode {
  NotPermitted = 'NOT_PERMITTED',
}

export type ScanTicketsInOrdersResult = {
  __typename?: 'ScanTicketsInOrdersResult'
  error?: Maybe<ScanTicketsInOrdersErrorCode>
  ok: Scalars['Boolean']['output']
}

export type SearchResults = {
  __typename?: 'SearchResults'
  artists: ArtistConnection
  events: EventConnection
  venues: VenueConnection
}

export enum StripeAccountLinkError {
  AlreadyOnboarded = 'ALREADY_ONBOARDED',
  Unauthorized = 'UNAUTHORIZED',
}

export type StripeAccountLinkResult = {
  __typename?: 'StripeAccountLinkResult'
  error?: Maybe<StripeAccountLinkError>
  ok?: Maybe<Scalars['String']['output']>
}

export type TicketSalesAttribution = {
  __typename?: 'TicketSalesAttribution'
  count: Scalars['Int']['output']
  participant?: Maybe<EventParticipant>
  percentage: Scalars['String']['output']
}

export type TicketStats = {
  __typename?: 'TicketStats'
  attributions: Array<TicketSalesAttribution>
  scannedCount: Scalars['Int']['output']
  totalCount: Scalars['Int']['output']
}

export type UpdateEventArtistsInput = {
  artistIds: Array<Scalars['ID']['input']>
  eventId: Scalars['ID']['input']
}

export type UpdateEventDescriptionInput = {
  description: Scalars['String']['input']
  eventId: Scalars['ID']['input']
}

export type UpdateEventGenresInput = {
  eventId: Scalars['ID']['input']
  genreIds: Array<Scalars['Int']['input']>
}

export type UpdateEventNameInput = {
  eventId: Scalars['ID']['input']
  name: Scalars['String']['input']
}

export type UpdateEventPosterInput = {
  eventId: Scalars['ID']['input']
  poster: Scalars['URL']['input']
}

export type UpdateEventPublishedInput = {
  eventId: Scalars['ID']['input']
  published: Scalars['Boolean']['input']
}

export type UpdateEventTimeInput = {
  dateTime: Scalars['DateTime']['input']
  eventId: Scalars['ID']['input']
}

export type UpdateEventVenueInput = {
  eventId: Scalars['ID']['input']
  venueId: Scalars['ID']['input']
}

export type UpdatePermissionsOptions = {
  permissionIds: Array<Scalars['Int']['input']>
  profileId: Scalars['ID']['input']
  profileType: ProfileType
  userId: Scalars['ID']['input']
}

export type User = Node & {
  __typename?: 'User'
  city: City
  coverPicture?: Maybe<Scalars['String']['output']>
  csrfToken?: Maybe<Scalars['String']['output']>
  dateOfBirth: Scalars['String']['output']
  description?: Maybe<Scalars['String']['output']>
  email: Scalars['String']['output']
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  permissions: Array<Permission>
  picture?: Maybe<Scalars['String']['output']>
  profiles: Array<Profile>
  role: Scalars['String']['output']
  selectedProfile?: Maybe<Profile>
  slug: Scalars['String']['output']
}

export type UserPermissionsArgs = {
  options: GetPermissionsOptions
}

export type UserProfileUpdateOptions = {
  cityId?: InputMaybe<Scalars['Int']['input']>
  id: Scalars['ID']['input']
  name?: InputMaybe<Scalars['String']['input']>
  slug?: InputMaybe<Scalars['String']['input']>
}

export type UserSignupInput = {
  cityId: Scalars['Int']['input']
  dateOfBirth: Scalars['String']['input']
  email: Scalars['EmailAddress']['input']
  name: Scalars['String']['input']
  password: Scalars['String']['input']
  passwordConfirmation: Scalars['String']['input']
  permissionInvitationToken?: InputMaybe<Scalars['String']['input']>
  termsAccepted?: InputMaybe<Scalars['Boolean']['input']>
}

export type Venue = Node & {
  __typename?: 'Venue'
  address: Scalars['String']['output']
  ageRestriction?: Maybe<AgeRestriction>
  audienceCapacity?: Maybe<Scalars['Int']['output']>
  city: City
  description: Scalars['String']['output']
  eventInvitations: Array<EventInvitation>
  events: EventConnection
  id: Scalars['ID']['output']
  links: Array<Link>
  name: Scalars['String']['output']
  picture?: Maybe<Scalars['String']['output']>
  slug: Scalars['String']['output']
}

export type VenueEventsArgs = {
  pagination: ForwardPagination
}

export type VenueConnection = Connection & {
  __typename?: 'VenueConnection'
  edges: Array<VenueEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']['output']
}

export type VenueEdge = Edge & {
  __typename?: 'VenueEdge'
  cursor: Scalars['Cursor']['output']
  node: Venue
}

export enum VenueProfileCreationError {
  NameTaken = 'NAME_TAKEN',
}

export type VenueProfileCreationResult = {
  __typename?: 'VenueProfileCreationResult'
  error?: Maybe<VenueProfileCreationError>
  ok: Scalars['Boolean']['output']
  venue?: Maybe<Venue>
}

export type VenueProfileUpdateOptions = {
  address?: InputMaybe<Scalars['String']['input']>
  audienceCapacity?: InputMaybe<Scalars['Int']['input']>
  cityId?: InputMaybe<Scalars['Int']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  id: Scalars['ID']['input']
  links?: InputMaybe<Array<LinkInput>>
  name?: InputMaybe<Scalars['String']['input']>
  picture?: InputMaybe<Scalars['String']['input']>
  slug?: InputMaybe<Scalars['String']['input']>
}

export type VenueSignupInput = {
  address: Scalars['String']['input']
  ageRestriction: AgeRestriction
  audienceCapacity: Scalars['Int']['input']
  cityId: Scalars['Int']['input']
  description: Scalars['String']['input']
  eventInvitationToken?: InputMaybe<Scalars['String']['input']>
  name: Scalars['String']['input']
}
